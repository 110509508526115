import React from 'react';
import { motion } from 'framer-motion';

const images = [
  { src: `${process.env.PUBLIC_URL}/img/grad-pic.png`, alt: "About Me 1", size: 280 },
  { src: `${process.env.PUBLIC_URL}/img/teddy.png`, alt: "About Me 2", size: 260 },
  { src: `${process.env.PUBLIC_URL}/img/boat-picture.png`, size: 300  },
  { src: `${process.env.PUBLIC_URL}/img/sf-pic.png`, alt: "About Me 4", size: 260  },
  { src: `${process.env.PUBLIC_URL}/img/frat.png`, alt: "About Me 5", size:  300  },
];

const MotionImage = motion.img;

export function AboutMe() {
  return (
    <section className="bg-[#161618] text-white relative overflow-hidden py-12 px-4 sm:py-20 sm:px-8" id="about-me">
      <div className="max-w-6xl mx-auto">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 lg:mb-12 text-primary text-center"
        >
          An Introduction About Me
        </motion.h2>
        <div className="flex flex-col lg:flex-row items-start gap-8 lg:gap-12">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="w-full lg:w-1/2 space-y-4 lg:space-y-6 text-base lg:text-lg text-left order-2 lg:order-1"
          >
            <p>
              I've recently graduated from the University of Oregon with a bachelor of science, majoring in business and economics, and a minor in legal studies. While taking classes, I joined Greek Life and was elected to the executive board of Phi-Delta-Theta, holding the Risk Management position.
            </p>
            <p>
              Today, the fraternity has grown to over 65 active members and re-established affiliations with the university and parent organization. My position on the executive board allowed me a unique opportunity to create foundational policy and structure.
            </p>
            <p>
              While in college I obtained a position at Amazon as a customer service associate. The role taught me essential people skills and built incredible professional relationships within the operations division. After learning the workflow of the position, I noticed a flaw in the returns process causing frustration for customers and associates. This led me to create a tool that reduced the gap between the customer's return and our internal systems recognizing the order, saving valuable time.
            </p>
            <p>
              During the pandemic, I learned how to program code and applied this skill by building servers. This ultimately led to the creation of online communities and projects such as tools created for Amazon and this website.
            </p>
            <p>
              I love finding opportunities that allow continuous learning and allow me to make fulfilling contributions. I've noticed through these servers how members gained a sense of community, just because of an interest in development. I find it extremely rewarding to hear positive feedback and It's motivating to see people find value through my projects.
            </p>
          </motion.div>
          <div className="w-full lg:w-1/2 relative h-[600px] lg:h-[1200px] order-1 lg:order-2 mb-8 lg:mb-0 hidden lg:block">
            {images.map((image, index) => (
              <motion.div
                key={index}
                className="absolute"
                style={{
                  top: `${index * 18}%`,
                  left: `${getDesktopPosition(index)}%`,
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 + index * 0.2, duration: 0.6 }}
              >
                <MotionImage
                  src={image.src}
                  alt={image.alt}
                  width={image.size}
                  height={image.size}
                  className="rounded-lg shadow-lg object-cover"
                  whileHover={{ 
                    scale: 1.05, 
                    rotate: index % 2 === 0 ? -3 : 3,
                    transition: { duration: 0.3 }
                  }}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function getDesktopPosition(index) {
  const positions = [10, 60, 5, 55, 20];
  return positions[index];
}