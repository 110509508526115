import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const projects = [
  { 
    id: 1, 
    title: "Amazon CXO Tools", 
    image: `${process.env.PUBLIC_URL}/img/amazon-locker.png`,
    excerpt: "Streamlined QR code and barcode generator for efficient returns processing.",
    description: "A streamlined QRcode and barcode generator designed for easy and efficient use. This simple, yet effective tool was developed to address the frustration of a delayed returns process, its intuitive design ensures that even those with minimal computer skills can use it effortlessly.",
    link: "https://ks1x.github.io/CXO_Tools/"
  },
  { 
    id: 2, 
    title: "BloomHQ", 
    image: `${process.env.PUBLIC_URL}/img/bloom-logo.png`,
    excerpt: "Digital solutions company offering custom website design and game server hosting.",
    description: "BloomHQ is a digital solutions company, it's all about helping people and businesses build their online presence in a way that's creative and impactful. I'm offering services like custom website design—so if someone needs a professional, responsive site, I can take care of it from start to finish. I'm also hosting game servers, which is something I'm passionate about, and creating content for social media, like lifestyle branding and campaigns, to help people grow their audience. It's kind of a mix of technical and creative work, but the idea is to make it super easy for clients to get high-quality, tailored solutions without any hassle. I want it to be a one-stop shop for everything digital.",
    link: "https://bloomhq.net/"
  },
  { 
    id: 3, 
    title: "Website Development", 
    image: `${process.env.PUBLIC_URL}/img/code.png`,
    excerpt: "Custom web development with a focus on gaming communities and server optimization.",
    description: "I've developed a passion for coding, which fueled my journey in developing game servers and creating custom scripts. From crafting immersive experiences to optimizing server performance, I enjoy pushing the boundaries of what's possible in gaming communities. Writing code isn't just a skill for me—it's an art form where I bring creativity and functionality together to solve problems and enhance player experiences. Whether it's designing unique mechanics or debugging intricate systems, I find immense satisfaction in every step of the development process.",
    link: "https://github.com/KS1X"
  },
];

export function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <section className="py-20 px-8 bg-[#161618]" id="projects">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
      >
        My Selected Projects
      </motion.h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="group relative aspect-video overflow-hidden rounded-lg cursor-pointer shadow-lg hover:shadow-xl transition-all duration-300 bg-[#2a2a2d]"
            onClick={() => setSelectedProject(project)}
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex flex-col items-start justify-end p-6 opacity-100 transition-opacity duration-300">
              <h3 className="text-2xl font-bold text-white mb-2">{project.title}</h3>
              <p className="text-sm text-gray-300 mb-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">{project.excerpt}</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-[#34bf8e] text-white px-4 py-2 rounded-full text-sm hover:bg-[#2da77c] transition-colors"
              >
                Learn More
              </motion.button>
            </div>
          </motion.div>
        ))}
      </div>
      <AnimatePresence>
        {selectedProject && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 px-4"
            onClick={() => setSelectedProject(null)}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-[#2a2a2d] p-8 rounded-lg max-w-2xl w-full mx-4 relative overflow-hidden"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-[#258865]/10 to-transparent opacity-50" />
              <div className="relative z-10">
                <h3 className="text-3xl font-bold mb-4 text-[#34bf8e]">{selectedProject.title}</h3>
                <p className="text-gray-300 mb-6 leading-relaxed">{selectedProject.description}</p>
                <div className="flex justify-between">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-[#f53535] text-white px-6 py-2 rounded-full hover:bg-[#d93737] transition-colors"
                    onClick={() => setSelectedProject(null)}
                  >
                    Close
                  </motion.button>
                  <motion.a
                    href={selectedProject.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-[#34bf8e] text-white px-6 py-2 rounded-full hover:bg-[#2da77c] transition-colors"
                  >
                    Visit Site
                  </motion.a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}