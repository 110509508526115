import React from 'react';

export function Skills() {
  const skillsRow1 = "Target Audience Engagement + Adaptability + Creative Vision + Visual Storytelling + Campaign Development + Brand Identity + Consumer Behavior + Digital Communication + ";
  const skillsRow2 = "Project Management & Execution + Cross-Functional Collaboration + Creative Content & Development + Brand Voice & Messaging + UI/UX + Activations + Stakeholder Communication + ";
  const skillsRow3 = "Consumer Behavior Understanding + Target Audience Engagement + Adaptability + Creative Vision + Campaign Development + Brand Identity + Consumer Behavior Understanding + ";

  return (
    <section className="overflow-hidden bg-[#161618] py-12">
      <h2 className="text-4xl md:text-5xl font-bold mb-12 text-primary text-center">
      Skills in Action
      </h2>
      <div className=" max-w-6xl mx-auto flex flex-col gap-2">
        <div className="relative flex whitespace-nowrap overflow-hidden">
          <div className="animate-scroll-faster sm:animate-scroll-right py-2 flex items-center text-xs sm:text-sm md:text-base max-w-full">
            <span className="inline-block">{skillsRow1.repeat(3)}</span>
          </div>
        </div>
        <div className="relative flex whitespace-nowrap overflow-hidden">
          <div className="animate-scroll-faster-reverse sm:animate-scroll-left py-2 flex items-center text-xs sm:text-sm md:text-base max-w-full">
            <span className="inline-block">{skillsRow2.repeat(3)}</span>
          </div>
        </div>
        <div className="relative flex whitespace-nowrap overflow-hidden">
          <div className="animate-scroll-faster sm:animate-scroll-right py-2 flex items-center text-xs sm:text-sm md:text-base max-w-full">
            <span className="inline-block">{skillsRow3.repeat(3)}</span>
          </div>
        </div>
      </div>
    </section>
  );
}