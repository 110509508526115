import React from 'react';
import { motion } from 'framer-motion';

export function Hero() {
  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-[#161618]" id='home'>
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute z-0 w-auto min-w-full min-h-full max-w-6xl object-cover"
      >
        <source src={`${process.env.PUBLIC_URL}/img/bg-nyc.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* SVG Overlay */}
      <div className="absolute inset-0 z-10 flex flex-col justify-end">
        <img
          src={`${process.env.PUBLIC_URL}img/slant-up-bottom.svg`}
          alt="Rough Edges"
          className="object-cover"
        />
      </div>

      {/* Content */}
      <div className="relative z-20 text-white px-8 py-20 max-w-5xl mx-auto w-full">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-start text-left"
        >
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mb-4 -ml-1"
          >
            Hello!
          </motion.h1>
          <motion.div
            initial={{ opacity: 0, scaleX: 0 }}
            animate={{ opacity: 1, scaleX: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="w-72 h-px bg-white mb-4 relative left-24"
          ></motion.div>
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-8">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="w-32 h-32 rounded-full overflow-hidden flex-shrink-0"
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/John-Profile-picture.gif`}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="text-lg sm:text-xl md:text-2xl max-w-2xl leading-relaxed"
            >
              I'm John Gwynn, I specialize in creating innovative solutions, and seamlessly merging business with technology.
            </motion.p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}