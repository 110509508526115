import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

export function Footer() {
  return (
    <footer className="py-12 px-8 bg-[#161618]">
      <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between items-center">
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-4 md:mb-0"
        >
          © 2024 John Gwynn. All rights reserved.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="flex space-x-6"
        >
        <a href="https://www.linkedin.com/in/jgwynn8/" className="text-white hover:text-[#34bf8e] transition-colors">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/KS1X" className="text-white hover:text-[#34bf8e] transition-colors">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        </motion.div>
      </div>
    </footer>
  );
}

