import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrendingUp, Code, Scale } from 'lucide-react';

const interests = [
  {
    title: 'Finance',
    icon: <TrendingUp className="w-12 h-12" />,
    description: 'Passionate about financial markets, investment strategies, and economic trends. I enjoy analyzing market data and exploring innovative fintech solutions.',
    color: 'from-blue-500 to-purple-500',
  },
  {
    title: 'Software Development',
    icon: <Code className="w-12 h-12" />,
    description: 'Enthusiastic about creating efficient, scalable, and user-friendly applications. I love exploring new technologies and solving complex problems through code.',
    color: 'from-green-500 to-teal-500',
  },
  {
    title: 'Legal',
    icon: <Scale className="w-12 h-12" />,
    description: 'Intrigued by the intersection of law and technology. I\'m particularly interested in intellectual property, data privacy, and the legal implications of emerging technologies.',
    color: 'from-red-500 to-pink-500',
  },
];

export function Interests() {
  const [activeInterest, setActiveInterest] = useState(null);

  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-[#161618] to-[#1c1c1e] text-white relative overflow-hidden">
      <div className="max-w-6xl mx-auto relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {interests.map((interest, index) => (
            <motion.div
              key={interest.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              className="relative group"
              onMouseEnter={() => setActiveInterest(interest.title)}
              onMouseLeave={() => setActiveInterest(null)}
            >
              <div className={`bg-gradient-to-br ${interest.color} p-8 rounded-lg shadow-lg transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-xl`}>
                <div className="flex items-center justify-center mb-6">
                  {interest.icon}
                </div>
                <h3 className="text-2xl font-bold mb-4 text-center">{interest.title}</h3>
                <AnimatePresence>
                  {activeInterest === interest.title && (
                    <motion.p
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-sm text-white/90 text-center"
                    >
                      {interest.description}
                    </motion.p>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <BackgroundAnimation />
    </section>
  );
}

function BackgroundAnimation() {
  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute bg-white/5 rounded-full"
          style={{
            width: Math.random() * 100 + 50,
            height: Math.random() * 100 + 50,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            y: [0, Math.random() * 100 - 50],
            opacity: [0.1, 0.3, 0.1],
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
}

