import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const experiences = [
  { 
    id: 1, 
    title: "College Education",
    text: "Applied Economics, Business, Law & Society", 
    author: "University of Oregon",
    image: `${process.env.PUBLIC_URL}/img/uo-logo.svg`,
    backgroundImage: `${process.env.PUBLIC_URL}/img/uo-background.png`,
    cta: "View Education",
    fullExperience: "During my time at the University of Oregon, I developed a strong foundation in applied economics, business strategies, and societal impacts. I engaged in various research projects, collaborated with peers on complex case studies, and participated in clubs that provided hands-on experience in my field of study. This comprehensive education has equipped me with the analytical skills and business acumen necessary to tackle real-world economic challenges."
  },
  { 
    id: 2, 
    title: "Professional Certifications",
    text: "Data Analytics and Program Management", 
    author: "Google",
    image: `${process.env.PUBLIC_URL}/img/google-logo.svg`,
    backgroundImage: `${process.env.PUBLIC_URL}/img/data.png`,
    cta: "My Certifications",
    fullExperience: "Completing Google's professional certifications in Data Analytics and Program Management has significantly enhanced my technical and managerial skills. The Data Analytics course honed my abilities in data cleaning, statistical analysis, and data visualization using tools like SQL, R, and Tableau. The Program Management certification equipped me with strategies for effective project planning, risk management, and team leadership. These certifications have been instrumental in applying data-driven decision-making and efficient project management techniques in my professional endeavors."
  },
  { 
    id: 3, 
    title: "Volunteer",
    text: "Community Engagement and Fundraising", 
    author: "American Red Cross",
    image: `${process.env.PUBLIC_URL}/img/red-cross.svg`,
    backgroundImage: `${process.env.PUBLIC_URL}/img/volunteer.png`,
    cta: "Fundraising Efforts",
    fullExperience: "As a volunteer with the American Red Cross, I've had the opportunity to make a meaningful impact in my community. I've been involved in organizing local blood drives, participating in disaster preparedness initiatives, and coordinating fundraising events. One of our key achievements was fundraising $260k annually for disaster relief efforts. This experience has not only allowed me to give back to the community but also developed my skills in event planning, public speaking, and stakeholder management."
  },
];

export function Resume() {
  const [selectedExperience, setSelectedExperience] = useState(null);

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 bg-[#161618]" id="resume">
      <div className="max-w-6xl mx-auto">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold mb-12 text-center"
        >
          What I've Accomplished
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {experiences.map((experience, index) => (
            <motion.div
              key={experience.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              className="group relative bg-white bg-opacity-5 backdrop-filter backdrop-blur-sm p-6 rounded-lg shadow-xl overflow-hidden"
            >
              <div 
                className="absolute inset-0 bg-cover bg-center z-0 opacity-30 transition-opacity duration-300 group-hover:opacity-50"
                style={{ backgroundImage: `url(${experience.backgroundImage})` }}
              />
              <div className="relative z-10">
                <div className="w-20 h-20 rounded-full overflow-hidden mb-4 bg-white p-2 mx-auto">
                  <img
                    src={experience.image}
                    alt={experience.author}
                    className="w-full h-full object-contain"
                  />
                </div>
                <h3 className="text-xl font-bold mb-2 text-[#34bf8e] text-center">{experience.title}</h3>
                <p className="text-lg text-white mb-2 text-center">{experience.text}</p>
                <p className="text-sm italic mb-4 text-gray-400 text-center">- {experience.author}</p>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-gradient-to-r from-[#34bf8e] to-[#258865] text-white px-6 py-2 rounded-full transition-all duration-300 hover:shadow-lg hover:shadow-[#34bf8e]/20"
                  onClick={() => setSelectedExperience(experience)}
                >
                  {experience.cta}
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedExperience && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 px-4"
            onClick={() => setSelectedExperience(null)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", damping: 20, stiffness: 300 }}
              className="bg-[#1c1c1e] p-8 rounded-lg max-w-2xl w-full mx-4 relative overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-[#258865]/10 to-transparent opacity-50" />
              <div className="relative z-10">
                <div className="flex items-center mb-6">
                  <div className="w-16 h-16 rounded-full overflow-hidden mr-4 bg-white p-1">
                    <img
                      src={selectedExperience.image}
                      alt={selectedExperience.author}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-[#34bf8e]">{selectedExperience.title}</h3>
                    <p className="text-white">{selectedExperience.author}</p>
                  </div>
                </div>
                <p className="text-gray-300 mb-6 leading-relaxed">{selectedExperience.fullExperience}</p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-[#34bf8e] text-white px-6 py-2 rounded-full hover:bg-[#2da77c] transition-colors"
                  onClick={() => setSelectedExperience(null)}
                >
                  Close
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}