import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link as ScrollLink } from 'react-scroll';

const menuItems = [
  { name: 'Home', href: 'home' },
  { name: 'About', href: 'about-me' },
  { name: 'Skills', href: 'resume' },
  { name: 'Projects', href: 'projects' },
  { name: 'Contact', href: 'final-cta' },
];

export function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflow = isOpen ? 'hidden' : '';
    }
  }, [isOpen]);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center p-8">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <a href="/">
            <img src={`${process.env.PUBLIC_URL}/img/sign-white.png`} alt="Logo" className="h-12" />
          </a>
        </motion.div>
        {!isOpen && (
          <motion.button
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <Menu size={24} />
          </motion.button>
        )}
      </nav>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={toggleMenu}
          >
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
              className="absolute right-0 top-0 bottom-0 w-full sm:w-64 bg-white bg-opacity-20 dark:bg-gray-800 dark:bg-opacity-20 backdrop-blur-lg shadow-lg"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="h-full flex flex-col">
                <div className="flex-1 flex items-center justify-center">
                  <motion.ul
                    className="p-4 sm:p-8 w-full"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, staggerChildren: 0.1 }}
                  >
                    {menuItems.map((item, index) => (
                      <motion.li
                        key={item.name}
                        className="mb-6 text-center"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1 * index }}
                      >
                        <ScrollLink
                          to={item.href}
                          smooth={true}
                          duration={500}
                          onClick={toggleMenu}
                          className="text-xl text-white hover:text-[#34bf8e] transition-all duration-300 ease-in-out transform hover:translate-x-2 hover:scale-105 cursor-pointer"
                        >
                          {item.name}
                        </ScrollLink>
                      </motion.li>
                    ))}
                    <motion.li
                      className="mt-8 text-center"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.1 * menuItems.length }}
                    >
                      <button
                        onClick={toggleMenu}
                        className="text-xl text-red-500 hover:text-red-600 transition-all duration-300 ease-in-out transform hover:translate-x-2 hover:scale-105 flex items-center justify-center w-full"
                      >
                        <X size={24} className="mr-2" />
                        Close Menu
                      </button>
                    </motion.li>
                  </motion.ul>
                </div>
                <div className="flex justify-center space-x-6 p-4">
                  <a href="https://www.linkedin.com/in/jgwynn8/" className="text-white hover:text-[#34bf8e] transition-colors">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </a>
                  <a href="https://github.com/KS1X" className="text-white hover:text-[#34bf8e] transition-colors">
                    <FontAwesomeIcon icon={faGithub} size="2x" />
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}