import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Mail, Linkedin, Github } from 'lucide-react';

export function FinalCTA() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="py-20 px-8 bg-gradient-to-br from-[#161618] to-[#1c1c1e] text-white relative overflow-hidden" id="final-cta">
      <div className="max-w-6xl mx-auto relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl md:text-5xl sm:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-[#34bf8e] to-[#258865]">
            Let's Create Something Amazing
          </h2>
          <p className="text-lg sm:text-xl mb-8 text-gray-300 max-w-2xl mx-auto">
            I'm always excited to collaborate on new projects and bring innovative ideas to life. Whether you have a specific project in mind or just want to explore possibilities, I'd love to hear from you!
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-center items-center gap-6"
        >
          <motion.a
            href="mailto:your.email@example.com"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group bg-gradient-to-r from-[#34bf8e] to-[#258865] text-white px-8 py-3 rounded-full text-lg font-medium transition-all duration-300 flex items-center gap-2 hover:shadow-lg hover:shadow-[#34bf8e]/20"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Get in Touch
            <motion.div
              animate={{ x: isHovered ? 5 : 0 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <ArrowRight className="w-5 h-5" />
            </motion.div>
          </motion.a>
          <div className="flex gap-4">
            <SocialIcon icon={<Linkedin />} href="https://www.linkedin.com/in/jgwynn8/" />
            <SocialIcon icon={<Github />} href="https://github.com/KS1X" />
            <SocialIcon icon={<Mail />} href="mailto:john@bloomhq.net" />
          </div>
        </motion.div>
      </div>

      <BackgroundAnimation />
    </section>
  );
}

function SocialIcon({ icon, href }) {
  return (
    <motion.a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className="bg-white/10 p-3 rounded-full hover:bg-white/20 transition-colors duration-300"
    >
      {icon}
    </motion.a>
  );
}

function BackgroundAnimation() {
  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute bg-white/5 rounded-full"
          style={{
            width: Math.random() * 100 + 50,
            height: Math.random() * 100 + 50,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            y: [0, Math.random() * 100 - 50],
            opacity: [0.1, 0.3, 0.1],
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
}

