import React from 'react';
import { Navigation } from '../components/Navigation';
import { Hero } from '../components/Hero';
import { AboutMe } from '../components/AboutMe';
import  { Resume } from '../components/Resume';
import { Projects } from '../components/Projects';
import { Skills } from '../components/Skills';
import { FinalCTA } from '../components/FinalCTA';
import { Footer } from '../components/Footer';
import { Interests } from '../components/Interests';


export function Home() {
  return (
    <main className="min-h-screen relative">
      <Navigation />
      <Hero id="home" />
      <AboutMe id="about-me" />
      <Resume id="resume" />
      <Skills id="skills" />
      <Interests id="interests" />
      <Projects id="projects" />
      <FinalCTA id="final-cta" />
      <Footer id="footer" />
    </main>
  );
}

